<template><div></div></template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
interface iParam {
    authUrl: string;
}
@Component({
    name: 'CiccUcaLoginForm',
})
export default class CiccUcaLoginForm extends Vue {
    @Prop({
        type: Object,
        default: () => {},
    })
    readonly params!: iParam;
    async mounted() {
        console.log('params--->', this.params);
        location.href = this.params.authUrl;
    }
}
</script>
