import { Vue, Component } from 'vue-property-decorator';
import QywxLoginForm from './LoginComponents/qywx.login.vue';
import CiccUcaLoginForm from './LoginComponents/ciccUca.login.vue';

//登录类型配置
const LoginComponentMap = {
    ciccwmUca: {
        component: 'CiccUcaLoginForm',
        title: '中金财富认证中心',
        loginType: 'ciccwmUca',
        description: '',
        icon: 'https://jtcospublic.ciccten.com/config-server/1702470828092238509/ciccwm.png',
    },
    qywx: {
        component: 'QywxLoginForm',
        title: '企业微信',
        description: '',
        loginType: 'qywx',
        icon: 'https://jtcospublic.ciccten.com/config-server/1702470071574869867/qywx.jpeg',
    },
};

const styleMap = {
    'ai': 'ai'
};

type LoginType = keyof typeof LoginComponentMap;

@Component({
    name: 'LoginLayout',
    components: {
        QywxLoginForm,
        CiccUcaLoginForm,
    },
})
export default class Login extends Vue {
    //当前激活的登录类型
    activeLoginType: LoginType = 'qywx';
    companies = [];
    currentCompany = null; // 当前选中的企业
    wkLoginUrl = '//rescdn.qqmail.com/node/ww/wwopenmng/js/sso/wwLogin-1.0.0.js';

    //获取当前登录类型form组件
    get activeLoginTypeComponent(): string {
        return LoginComponentMap[this.activeLoginType]?.component;
    }

    //获取当前激活的登录类型的参数
    get activeLoginTypeParams() {
        return this.$store.state.loginParams?.params?.find((it: Record<string, any>) => {
            return it.loginType === this.activeLoginType;
        })?.params;
    }

    //获取未激活的登录类型列表
    get inactiveLoginTypeList() {
        return Object.values(LoginComponentMap).filter(it => {
            return (
                it.loginType !== this.activeLoginType &&
                this.$store.state.loginParams?.params?.find((item: Record<string, any>) => {
                    return item.loginType === it.loginType;
                })
            );
        });
    }

    //更改登录类型
    changeLoginType(item: Record<string, any>) {
        this.activeLoginType = item.loginType;
    }

    async mounted() {
        console.log('login component ', this.activeLoginTypeComponent, this.activeLoginType);
    }

    async beforeMount() {
        console.log('start time', new Date().getTime());
        console.log('start--->', this.$store.state);
        //获取默认的登录方式
        this.activeLoginType = this.$store.state.loginParams?.defaultLoginType || this.activeLoginType;
    }

    get style() {
        return styleMap[this.$route.query?.style] || '';
    }
}
