import App from './App.vue';
import router from './router';
import '@web/assets/css/index.scss';
import initDevLogin from '@jv/midway-sso/web/initDevLogin';
import VueRouter from 'vue-router';
import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import { axios } from '@jv/egg-jv-common';
async function mountApp() {
    await initDevLogin(window.__INITIAL_STATE__.config.prefix || '');
    Vue.use(VueRouter);
    Vue.use(ElementUI);
    // ajax请求
    // 当有指定部署目录时，就用部署目录来当baseURL
    if (window.__INITIAL_STATE__.config.prefix) {
        axios.baseURL = `/${window.__INITIAL_STATE__.config.prefix}/`;
    }
    // 兼容一下以前的写法
    Vue.prototype.$ajax = axios;
    Vue.prototype.$store = {
        state: {
            ...window.__INITIAL_STATE__,
        },
    };
    if (/console=1/.test(location.search) || location.hostname === 'testoa.ciccjinteng.cn') {
        import(/* webpackChunkName: "vconsole" */ 'vconsole').then(({ default: VConsole }) => {
            // @ts-ignore
            window.v = new VConsole();
        });
    }
    const app = new Vue({
        router,
        render: h => h(App),
    });
    app.$mount('#app');
}
mountApp();

export default mountApp;
