import VueRouter from 'vue-router';
import LoginPage from '../login.vue';

const routes: Array<any> = [
    {
        path: '/',
        component: LoginPage,
    },
    {
        path: '/login',
        component: LoginPage,
    },
    {
        path: '*',
        component: LoginPage,
    },
];

const { prefix } = window.__INITIAL_STATE__.config;

const router = new VueRouter({
    routes, // (缩写) 相当于 routes: routes
    mode: 'history',
    base: prefix ? `/${prefix}/` : '/',
});

export { routes };
export default router;
