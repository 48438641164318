<template>
    <div>
        <div v-if="isWXWork" class="qywx-container">
            <div v-if="params.length > 1">
                <div class="select-company">
                    <span>请选择所属企业</span>
                    <el-divider style="margin-top: 12px"></el-divider>
                </div>
                <div v-for="item in params" :key="item.corId" class="text item">
                    <el-button
                        :type="Number(sActiveCorId) === item.corId ? 'primary' : ''"
                        @click="redirectUrl(getWXWorkAuthUrlByConf(item))"
                        style="width: 100%"
                        >{{ item.corName }}</el-button
                    >
                </div>
                <div class="container">
                    <div id="ww_login"></div>
                </div>
            </div>
        </div>
        <el-tabs v-else style="height: 100%" v-model="sActiveCorId" stretch class="pc-container">
            <el-tab-pane v-for="item in params" :key="item.corId" :name="item.corId.toString()" :lazy="true">
                <span slot="label">{{ item.corName }}</span>
            </el-tab-pane>
            <div class="container">
                <div id="ww_login" ref="wwContainer"></div>
            </div>
        </el-tabs>
    </div>
</template>
<style lang="scss">
.pc-container {
    .container {
        display: flex;
        justify-content: center;
        max-height: 380px;
        overflow: hidden;
        #ww_login {
            width: 320px;
        }
    }
}
.qywx-container {
    height: 100%;
    max-height: 380px;
    padding-left: 28px;
    padding-right: 28px;
    padding-bottom: 30px;
    .item {
        margin-top: 20px;
    }
    .container {
        visibility: hidden;
        //width: 1px;
        //height: 1px;
        position: absolute;
        left: -5000px;
    }
    .select-company {
        text-align: center;
        margin-top: 12px;
    }
}
</style>

<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { get as getCookie } from 'js-cookie';
import * as ww from '@wecom/jssdk';

interface iQywxLoginConf {
    authUrl: string;
    corId: number;
    corName: string;
    priority: number;
    qywxAgentId: string;
    qywxCorpId: string;
}

@Component({
    name: 'QywxLoginForm',
})
export default class QywxLoginForm extends Vue {
    @Prop({
        type: Array,
        default: () => [],
    })
    readonly params!: iQywxLoginConf[];

    //优先级
    sActiveCorId = '';

    //是否企微内打开
    get isWXWork() {
        return this.$store.state.isWXWork;
    }

    @Watch('sActiveCorId')
    onActiveCorIdChange(newVal: string) {
        //非企业微信打开才需要渲染二维码
        if (!this.isWXWork) {
            const corItem = this.params.find(it => {
                return it.corId === Number(newVal);
            });
            if (corItem) this.renderQywxQRCode(corItem);
        }
    }

    getWXWorkAuthUrlByConf(corConf: iQywxLoginConf) {
        const callBackUrl = encodeURIComponent(corConf.authUrl);
        return `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${corConf.qywxCorpId}&redirect_uri=${callBackUrl}&response_type=code&scope=snsapi_base&state=jvAccount#wechat_redirect`;
    }

    renderQywxQRCode(corConf: iQywxLoginConf, clear = true) {
        clear && this.$refs['wwContainer'] && ((this.$refs['wwContainer'] as HTMLElement).innerHTML = '');
        let _self = this;
        ww.createWWLoginPanel({
            el: '#ww_login',
            params: {
                login_type: ww.WWLoginType.corpApp,
                appid: corConf.qywxCorpId,
                agentid: corConf.qywxAgentId,
                redirect_uri: corConf.authUrl,
                state: 'jvAccount',
                redirect_type: ww.WWLoginRedirectType.top,
                panel_size: ww.WWLoginPanelSizeType.small,
            },
            onCheckWeComLogin({ isWeComLogin }) {
                console.log(corConf.qywxCorpId, isWeComLogin);
                console.log('onCheckWeComLogin time', new Date().getTime());
                if (isWeComLogin && _self.isWXWork) {
                    _self.redirectUrl(_self.getWXWorkAuthUrlByConf(corConf));
                }
            },
        });
    }

    //微信内打开直接跳转链接
    redirectUrl(url: string) {
        console.log('jump time', new Date().getTime());
        //href跳转很慢，改用relace试试
        // location.href = url;
        location.replace(url);
        console.log('jump over time', new Date().getTime());
    }

    async mounted() {
        console.log('qywx mounted time', new Date().getTime());
        console.log('qywx params--->', this.params);
        //初始化id
        this.sActiveCorId =
            getCookie('jvCompanyId') ||
            this.params.find(it => it.priority === 1)?.corId?.toString() ||
            this.params[0].corId.toString();
        if (this.isWXWork) {
            //只有一个时，就是第一个登录
            if (this.params.length === 1) {
                this.redirectUrl(this.getWXWorkAuthUrlByConf(this.params[0]));
            } else {
                //多个时检测
                for (let i = 0; i < this.params.length; i++) {
                    this.renderQywxQRCode(this.params[i], false);
                }
            }
        }
    }
}
</script>
